(function (window) {
  window._env = window.__env || {};

  window._env.apiUrl = 'http://localhost:8080';
  window._env.appUrl = 'http://localhost:4200'; //For Production build

  window._env.enableDebug = true;
  window._env.GOOGLE_PROVIDER_ID = "327497164694-fu2kjti6lfee7c32ntrfcsvgdjkau54t.apps.googleusercontent.com";
  window._env.blue_logo = 'newton_logo.png';
  window._env.white_logo = 'newton_logo.png';
}(this));
